import React from 'react'

import Fragment from 'react-dot-fragment'

const Card = function (props) {
  const {
    id,
    custom,
    iconName,
    iconClassNames,
    iconSize,
    iconType,
    children,
    title,
    titleClassNames,
    width
  } = props

  const determineIconSize = iconSize || '24'

  return (
    <div id={id} className={`${width || 'max-w-sm'} p-6 bg-white border border-white rounded-xl mb-3.5`}>
      {
        custom ?
        <Fragment>{children}</Fragment>
        :
        <div>
          <div className='mb-5 flex items-center space-x-2'>
            {
              iconName &&
              <div>
                {
                  iconType === 'solid' ?
                  <hero-icon-solid name={iconName} width={determineIconSize} height={determineIconSize} class={iconClassNames || ''}></hero-icon-solid>
                  :
                  <hero-icon-outline name={iconName} width={determineIconSize} height={determineIconSize} class={iconClassNames || ''}></hero-icon-outline>
                }
              </div>
            }
            <h5 className={`${titleClassNames || 'text-sm font-normal text-gray-tones-900'}`}>{title}</h5>
          </div>
          {children}
        </div>
      }
    </div>
  )
}

export default Card
