import React from 'react';
import { formatDistanceToNow, formatDistanceToNowStrict } from 'date-fns';

const TimeAgo = ({ timestamp, isStrict }) => {
  const strictTimeAgo = formatDistanceToNowStrict(new Date(timestamp), { addSuffix: true })
  const nonStrictTimeAgo = formatDistanceToNow(new Date(timestamp), { addSuffix: true });
  
  const timeAgo = isStrict ?  strictTimeAgo : nonStrictTimeAgo;

  return <span>{timeAgo}</span>;
};

export default TimeAgo;
