const coreUtils = {
    mobilecheck() {
        // http://detectmobilebrowsers.com/
        var isMobile = false;
        (function (a) {
            if (/(android|bb\d+|meego).+mobile|ServiceSeeking\/Mobile\/|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
                isMobile = true;
            }
        })(this.userAgent());
        return isMobile;
    },

    userAgent() {
        return navigator.userAgent || navigator.vendor || window.opera;
    },

    userLoggedIn: function () {
        return typeof window.App.currentUserId !== 'undefined';
    },

    userHasValidPhone: function () {
        if (typeof window.App.userPhoneIsValid === 'undefined') {
            return false;
        }

        return window.App.userPhoneIsValid.toString() === "true";
    },

    urlInspector: {
        urlQueryString: location.search,
        // hasParam: utility function to find param name in url - return true / false
        hasParam: function (paramName) {
            var parameters, result;
            parameters = this.urlQueryString.substring(1).split('&');
            result = parameters.filter(function (param) {
                var derivedName = param.split('=')[0];
                return derivedName === paramName;
            });
            return Boolean(result.length)
        },
        getURLParameter(name) {
            return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(this.urlQueryString) || [, ''])[1].replace(/\+/g, '%20')) || null;
        }
    },

    generateGuid: function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    },


    googleAnalytics: function (params) {
        if (typeof window.ga !== 'undefined') {
            var trackerName = window.ga.getAll()[0].get('name');
            window.ga(trackerName + '.send', 'event', params);
            // window.ga(trackerName + '.send', 'event', { eventCategory: 'category1', eventAction: 'action1', eventValue: 0 });
            // window.ga.getAll()[0].send('event', params)
            // window.ga('send', params);
            console.log('google analytics hit!');
        }
    },

    // TODO: Remove once conflict with Wizard.jsx is fixed
    scrollToV2: function (elem, offset, timing, mobileHA) {
        var ha = mobileHA || true; // set mobileHA: false to avoid potential flickering issues on iOS:
        var off = offset || 0;
        var duration = timing || 1000; // 1 second
        $('html')
            .velocity('scroll', {
                duration: duration,
                offset: elem.offset().top - off,
                mobileHA: ha
            });
    },

    scrollTo: function (elem, offset, timing, mobileHA) {
        var ha = mobileHA || true; // set mobileHA: false to avoid potential flickering issues on iOS:
        var off = offset || 0;
        var duration = timing || 1000; // 1 second
        $('html')
            .velocity('scroll', {
                duration: duration,
                offset: elem.offset().top - off,
                mobileHA: ha
            });

        // $('html, body') .animate({scrollTop: elem.offset().top - offset}, timing);
    },

    appMsg: function (obj) {
        var wk = window.webkit;
        window.android && window.android.sendToAndroid(JSON.stringify(obj));
        if (wk) {
            try {
                // chrome ios uses webkit so does our ios app but chrome only handles upto `webkit.messageHandlers`
                wk.messageHandlers.callbackHandler.postMessage(obj);
            }
            catch (e) { }
        }
    },

    doesObjectExists(obj) {
        return (typeof obj !== 'undefined' && obj !== null) ? true : false
    },

    preventEnterSubmit(e) {
        if (e.which === 13) {
            var $targ = $(e.target);

            if (!$targ.is('textarea') && !$targ.is(':button,:submit')) {
                var focusNext = false;

                // $(this).find(':input:visible:not([disabled],[readonly]), a').each(function() {
                $(this).find(':input:visible:not([disabled],[readonly])').each(function () {
                    if (this === e.target) {
                        focusNext = true;
                    }
                    else if (focusNext) {
                        $(this).focus();
                        return false;
                    }
                });

                return false;
            }
        }
    },

    objectKeys: function (obj) {
        var keys = [];
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                keys.push(key);
            }
        }
        return keys;
    }
}

export default coreUtils
