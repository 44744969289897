import React from 'react'

import categoryAutocomplete from 'common/src/autocomplete/categoryAutocomplete'
import seoKeywordAutocomplete from 'common/src/autocomplete/seoKeywordAutocomplete'
import eventEmitter from 'common/src/eventEmitter'
import Data from 'business_sign_up/wizard/data/data'

class IndustryInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      keyword: '',
      disabled: props.disabled
    }

    this.handleChange = this.handleChange.bind(this)
    this.updateState  = this.updateState.bind(this)
  }

  componentDidMount() {
    this.onAutocomplete()
    eventEmitter.addListener('sign_up:occupationSelected', this.updateState)
  }

  updateState() {
    this.setState({ keyword: Data.job_filter.category })
  }

  componentWillUnmount() {
    eventEmitter.removeListener('sign_up:occupationSelected', this.updateState)
  }

  onAutocomplete() {
    if (this.props.searchType === 'occupation') {
      categoryAutocomplete.occupationAutocomplete(`.keyword-${this.props.guId}`, this.props.handleSelectedItem, this.props.resultCallback)
    } else {
      categoryAutocomplete.taskAutocomplete(`.keyword-${this.props.guId}`, this.props.handleSelectedItem, this.props.preferKeywords)
    }
  }

  handleChange(event) {
    this.setState({ keyword: event.target.value }, this.props.handleItemChange(event))
  }

  render() {
    return(
      <input type="text"
        autoFocus
        className={`form-control keyword-${this.props.guId}`}
        name="keyword"
        id={`keyword-${this.props.guId}`}
        placeholder={this.props.placeholder || 'Enter Industry'}
        value={this.state.keyword}
        onChange={this.handleChange}
        disabled= { this.state.disabled ? "disabled" : ""}
      />
    )
  }
}

export default IndustryInput
