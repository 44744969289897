const stringUtils = {
    // turns alice-springs to Alice Springs
    toTitleCase(string) {
        return this.dashesToSpaces(string).replace(/\w\S*/g, this.capitalise);
    },

    // turns `alice-springs-north` to `alice springs north`
    dashesToSpaces(string) {
        return string.replace(/-/g, ' ');
    },

    // capitalises the first element in the string
    capitalise(string) {
        return string.charAt(0).toUpperCase() + string.substr(1);
    },

    decapitalise(string) {
        return string.charAt(0).toLowerCase() + string.substr(1);
    },

    pluralize(word, count) {
        if (typeof count === 'string') {
            return word + 's';
        }

        return count <= 1 ? word : word + 's';
    },

    toCamelCase(string) {
        return this.decapitalise(
            string.replace(/\W+(.)/g,function(_match,chr){
                return chr.toUpperCase()
            })
        )
    }
}

export default stringUtils
